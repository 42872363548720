import React, { useState } from "react"
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Collapse,
} from "reactstrap"

import { Link } from "gatsby"

import logo from "../../assets/header/logo.svg"
import { useLocation } from "@reach/router"
import "./Header.css"

const Header = () => {
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  //change document title
  if (typeof window !== `undefined`) {
    window.document.title = "C3 Fund, LP"
  }
  return (
    <div className="header">
      <div className="header-container">
        <Navbar light expand="sm" style={{ textAlign: "right", width: "100%" }}>
          <NavbarBrand>
            <Link to="/">
              <img className="nav-logo" src={logo} alt="C#-logo" />
            </Link>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse
            className="header-navlinks"
            style={{ textAlign: "center", outline: "none" }}
            isOpen={isOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink>
                  <Link
                    className={"nav-link " + (pathname === "/" ? "bold" : "")}
                    to="/"
                  >
                    Home
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link
                    className={
                      "nav-link " + (pathname === "/about" ? "bold" : "")
                    }
                    to="/about"
                  >
                    About
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link
                    className={
                      "nav-link " + (pathname === "/portfolio" ? "bold" : "")
                    }
                    to="/portfolio"
                  >
                    Portfolio
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link
                    className={
                      "nav-link " + (pathname === "/contact" ? "bold" : "")
                    }
                    to="/contact"
                  >
                    Contact
                  </Link>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Link
                    className={
                      "nav-link " + (pathname === "/faq" ? "bold" : "")
                    }
                    to="/faq"
                  >
                    FAQ
                  </Link>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  )
}

export default Header
