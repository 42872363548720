import React from "react"
import "./FooterNew.css"
import mailIcon from "../../assets/footer/mail.svg"
import phoneIcon from "../../assets/footer/phone.svg"
import { tels, emails } from "../../../site_data/contactdata.json"
import { navigate } from "gatsby"
const FooterNew = () => {
  return (
    <div className="footer">
      <div onClick={() => navigate("/")} className="footer_logo">
        <img src="/logo.svg" alt="IC" />
      </div>
      <div className="footer_description">
        <p>
          At C3 Fund, LP, our vision is to build a brand that becomes one of the
          top choices of investment for accredited investors.
        </p>
      </div>
      <div className="footer_links">
        <span onClick={() => navigate("/about")}>About</span>
        <span onClick={() => navigate("/contact")}>Contact Us</span>
        <span onClick={() => navigate("/portfolio")}>Portfolio</span>
        <span onClick={() => navigate("/faq")}>FAQs</span>
      </div>
      <div className="footer_contact">
        <div>
          <img src={phoneIcon} />
          <p>Tel: 503-880-0928</p>
        </div>
        <div>
          <img src={phoneIcon} />
          <p>Tel: +1.778.202.1000 (Paul Mangat for Clients in Canada)</p>
        </div>
        <div>
          <img src={mailIcon} />
          <p>Rohit@CThreeFund.com</p>
        </div>
        <div>
          <img src={mailIcon} />
          <p>Josh@CThreeFund.com</p>
        </div>
      </div>
    </div>
  )
}

export default FooterNew
